import React, { Fragment, useEffect } from "react"
import styled from "@emotion/styled"
import { injectIntl, Link } from "gatsby-plugin-intl"
import TvAdsSlider from "../TvAdsSlider"
import ReactPlayer from "react-player/lazy"
import { isBrowser } from "../../../context/ApolloContext"

const $ = isBrowser ? require("jquery") : {}

const TvAdsSection = ({ intl, data, color, page }) => {
  const handleHashScrolling = () => {
    $(document).ready(function() {
      $("html, body").hide()
      if (window.location.hash === "#tv") {
        setTimeout(function() {
          $("html, body")
            .scrollTop(0)
            .show()
          $("html, body").animate(
            {
              scrollTop: $(window.location.hash).offset().top - 180,
            },
            1500
          )
        }, 0)
      } else {
        $("html, body").show()
      }
    })
  }

  useEffect(() => {
    isBrowser && handleHashScrolling()
  }, [])
  return (
    <TvAdsWrapper color={color} page={page} id="tv">
      <Wrapper page={page}>
        {page != "newHome" && (
          <SectionHeading page={page}>{data.title}</SectionHeading>
        )}
        <SectionWrapper>
          <Sections page={page}>
            {page === "LoveUs" ? (
              <Title page={page}>{data.love_us_video_title}</Title>
            ) : page === "newHome" ? (
              <Title page={page}>Presse</Title>
            ) : (
              <Title>{data.video_title}</Title>
            )}
            {page === "LoveUs" ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data.love_us_video_text,
                }}
              />
            ) : page === "newHome" ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html:
                    "Ils ont testé nos solutions et les ont approuvées. Découvrez les articles de presse, passages télévisés, émissions de radio...",
                }}
              />
            ) : (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data.video_text,
                }}
              />
            )}

            <PlayerWrapper>
              <ReactPlayer
                url={
                  page === "LoveUs" && intl.locale === "fr"
                    ? "https://www.percko.com/videos/percko.com/eur-fr/ils-nous-aiment/ils_nous_aiment_-_video_1_bfm_business.mp4"
                    : data.video_url
                }
                stopOnUnmount={true}
                light={
                  page === "LoveUs" && intl.locale === "fr"
                    ? "https://static.percko.com/uploads/553ebeb9-5dd7-42d3-8290-7b35e749e100.jpg"
                    : "https://static.percko.com/uploads/cdd0b3dd-1138-47c4-b142-1c3480642561.jpg"
                }
                playing={true}
                controls={true}
                muted={true}
                width="100%"
                height="100%"
              />
            </PlayerWrapper>
          </Sections>
          <Sections page={page}>
            {page === "LoveUs" ? (
              <Title page={page}>{data.love_us_slider_title}</Title>
            ) : page === "newHome" ? (
              <Fragment />
            ) : (
              <Title>{data.slider_title}</Title>
            )}
            {page === "LoveUs" ? (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data.love_us_slider_text,
                }}
              />
            ) : page === "newHome" ? (
              <Fragment />
            ) : (
              <Text
                dangerouslySetInnerHTML={{
                  __html: data.slider_text,
                }}
              />
            )}
            <TvAdsSlider
              data={data.slider.list}
              sliderLinkText={data.slider_link_text}
            ></TvAdsSlider>
          </Sections>
        </SectionWrapper>
        {page != "LoveUs" &&
          page != "home" &&
          page != "newHome" &&
          intl.locale === "fr" && (
            <ShopButtonWrapper>
              <ShopButton to="/shop/">Découvrir nos produits</ShopButton>
            </ShopButtonWrapper>
          )}
      </Wrapper>
    </TvAdsWrapper>
  )
}

const PlayerWrapper = styled.div`
  height: 305px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 20px;
  border-radius: 8px;
  position: relative;

  .react-player__preview {
    position: absolute;
    top: 0px;
  }

  iframe,
  video {
    @media (max-width: 550px) {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }

  @media (max-width: 550px) {
    padding-top: 56%;
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
  }
`

const TvAdsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 400px;
  width: 100%;
  padding: ${({ color }) => (color ? "0px" : "30px 20px")};
  background: ${({ color }) => (color ? color : "#fafafa")};

  @media (max-width: 1050px) {
    padding: ${({ color }) => (color ? "0px 0px 80px" : "30px 20px 80px")};
  }

  @media (max-width: 550px) {
    padding: ${({ color, page }) =>
      color
        ? "0px 0px 80px"
        : page === "home"
        ? "40px 20px 80px"
        : "30px 20px 80px"};
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ page }) => (page === "newHome" ? "1140px" : "1080px")};
  margin: 0px auto;
`

export const SectionHeading = styled.h2`
  font-size: ${({ page }) =>
    page === "home" ? "40px" : page === "newHome" ? "40px" : "36px"};
  font-family: "BebasNeueBold";
  font-weight: bold;
  color: #262626;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  line-height: 1;
  letter-spacing: 0px;
  text-transform: uppercase;

  @media (max-width: 850px) {
    font-size: ${({ page }) =>
      page === "home" ? "36px" : page === "newHome" ? "36px" : "28px"};
  }

  @media (max-width: 550px) {
    margin: 0px;
    line-height: 1;
    font-size: 36px;
    padding: 0px;
    align-items: baseline;
    width: 100%;
    text-align: left;
  }

  &:after {
    content: ${({ page }) => (page === "home" ? "inherit" : "")};
    height: 4px;
    background: #262626;
    width: 150px;
    margin: 0 auto;
    margin-top: 5px;

    @media (max-width: 550px) {
      margin: 5px 0px 15px;
    }
  }
`

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 850px) {
    padding: 0 20px;
    flex-direction: column;
  }

  @media (max-width: 550px) {
    flex-direction: column;
    padding: 0px;
  }
`

const Sections = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: ${({ page }) =>
    page === "newHome" ? "center" : "flex-start"};
  max-width: 48%;

  @media (max-width: 850px) {
    max-width: 100%;
  }

  @media (max-width: 550px) {
    margin: 0px;
  }
`

const Title = styled.h3`
  margin: 0px;
  font-family: ${({ page }) =>
    page === "newHome" ? "BebasNeueBold" : "Museo"};
  font-weight: 700;
  font-size: ${({ page }) =>
    page === "LoveUs" ? "1.5em" : page === "newHome" ? "40px" : "1.3em"};
  line-height: ${({ page }) => (page === "newHome" ? "1" : "1.5em")};
  text-transform: uppercase;
`

const Text = styled.div`
  font-family: "Gotham Book";
  margin-bottom: 20px;
  line-height: 20px;

  @media (max-width: 550px) {
    margin: 10px 0px;
  }

  p {
    margin: 0px;
  }

  p > a {
    font-family: "GothamLight";
    font-weight: bold;
    text-decoration: underline;
  }
`

const ShopButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
  top: 10px;

  @media (max-width: 1050px) {
    top: 65px;
  }
`

const ShopButton = styled(Link)`
  border-radius: 5px;
  background-color: #262626;
  cursor: pointer;
  color: #fff;
  font-size: 13px;
  padding: 10px 20px;
  font-weight: bold;
  text-align: center;

  :hover {
    color: #fff;
  }

  @media (max-width: 550px) {
    width: 100%;
  }
`

export default injectIntl(TvAdsSection)
